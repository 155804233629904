<template>
  <div class="evadetail">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>受检管理</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goback1">受检评估</el-breadcrumb-item>
          <el-breadcrumb-item>详情页</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="changeBox">
          <div class="orgdata" @click="getOrgData">下载源数据</div>
          <!-- 上传数据  .zip -->
        <el-upload
          :limit="1" 
          action=""
          :show-file-list="false"
          :auto-upload="true"
          :before-upload="beforeUpload"
          :http-request="httprequest"
          :on-remove="remove"
        >
        <div class="orgdata">上传数据</div>
        </el-upload>
        <el-popconfirm
              confirm-button-text='确认'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="getBackImg"
              title="重新计算将会覆盖当前已标记图片"
            >
            <div class="orgdata" slot="reference">重新计算背部图</div>
          </el-popconfirm>
          
        <div class="orgdata" style="width:130px" @click="updateMark">更新算法计算结果</div>
       
      </div>
      <div class="baseInfo">
          <div class="title">
            <span class="blueTitle">基本信息</span>
            <span class="reportStatus" style="color:#FC5555" v-if="pageInfo.status =='处理中' ">{{pageInfo.status}}</span>
            <span class="reportStatus" style="color:#858585" v-if="pageInfo.status =='已处理' ">{{pageInfo.status}}</span>
          </div>
          <!-- 信息展示 -->
          <div class="infomation" v-if="topData">
              <span class="info-text">姓名：{{topData.examineName}}</span>
              <span class="info-text" v-if="(topData.gender!=null)&&(topData.gender==0)">性别：女</span>
              <span class="info-text" v-if="(topData.gender!=null)&&(topData.gender==1)">性别：男</span>
              <span class="info-text">年龄：{{pageInfo.age}}</span>
              <span class="info-text">身份证号：{{topData.cardId}}</span>
              <span class="info-text">手机号：{{topData.mobile}}</span>
               <span class="info-text">受测机构：{{topData.orgName}}</span>
              <span class="info-text">上传时间：{{pageInfo.createTime}}</span>
          </div>
      </div>
      <div class="baseBottom">
          <!-- box-top -->
          <div style="display:flex;justify-content: space-between">
            <span class="blueTitle">评估图像</span>
            <el-popconfirm
              confirm-button-text='成功'
              cancel-button-text='失败'
              cancel-button-type="Primary"
              icon="el-icon-info"
              icon-color="#00cccc"
              @confirm="sendSMS"
              @cancel="nosendSMS"
              title="请选择发送短信的类型"
            >
              <span style="text-decoration:underline;color:#009dd9;font-size:16px;cursor:pointer" slot="reference">发送短信通知</span>
          </el-popconfirm>
          <span style="text-decoration:underline;color:#009dd9;font-size:16px;cursor:pointer" @click="startFailProp">不合格数据</span>
          </div>
          <div class="org-img" v-if="((pageInfo.aheadWaistImage!=null)&&(pageInfo.aheadWaistImage!=null)&&(pageInfo.behindWaistImage!=null))">原始图像</div>
          <div class="imgBox">
              <div class="imginner" v-if="pageInfo.backHalfImage!=null">
                <viewer :images="[pageInfo.backHalfImage]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                        <img class="img" alt="" :src="pageInfo.backHalfImage" >
                    </el-tooltip>
                </viewer>
                <div class="img-title">背部站立位半身照</div>
              </div>
              <!-- <el-empty v-if="pageInfo.backHalfImage===null" :image-size="252" description="暂无图片"></el-empty> -->

              <div class="imginner" v-if="pageInfo.aheadWaistImage!=null">
                <viewer :images="[pageInfo.aheadWaistImage]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                      <img class="img" alt="" :src="pageInfo.aheadWaistImage">
                    </el-tooltip>
                </viewer>
                <div class="img-title">前面90°弯腰位</div>
              </div>
              <!-- <el-empty v-if="pageInfo.aheadWaistImage===null" :image-size="252" description="暂无图片"></el-empty> -->

              <div class="imginner" v-if="pageInfo.behindWaistImage!=null">
                <viewer :images="[pageInfo.behindWaistImage]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                          <img class="img" alt="" :src="pageInfo.behindWaistImage">
                    </el-tooltip>
                </viewer>
                <div class="img-title">背面90°弯腰位</div>
              </div>
              <!-- <el-empty v-if="pageInfo.behindWaistImage===null" :image-size="252" description="暂无图片"></el-empty> -->

              <div class="imginner" v-if="pageInfo.xraysFileZ!=null">
                <viewer :images="[pageInfo.xraysFileZ]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                        <img class="img" alt="" :src="pageInfo.xraysFileZ" >
                    </el-tooltip>
                </viewer>
                <div class="img-title">X光片（站立位全脊柱正位）</div>
              </div>
              <!-- <el-empty v-if="pageInfo.xraysFileZ===null" :image-size="252" description="暂无图片"></el-empty> -->
          </div>
          <!-- 计算结果  目前计算结果还没有字段-->
          <div class="data-img" v-if="(pageInfo.xraysFileMarkZ !=null)||(pageInfo.backHalfImageMark !=null)||(pageInfo.aheadWaistImageMark !=null)||(pageInfo.behindWaistImageMark !=null)">计算图像</div>
          <div class="imgBox" v-if="(pageInfo.xraysFileMarkZ !=null)||(pageInfo.backHalfImageMark !=null)||(pageInfo.aheadWaistImageMark !=null)||(pageInfo.behindWaistImageMark !=null)">
              <div class="imginner" v-if="(pageInfo.backHalfImageMark !=null)">
                <viewer :images="[pageInfo.backHalfImageMark]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                        <img class="img" alt="" :src="pageInfo.backHalfImageMark" >
                    </el-tooltip>
                </viewer>
                <div class="img-title">背部站立位半身照</div>
              </div>

              <div class="imginner" v-if="(pageInfo.aheadWaistImageMark !=null)">
               <viewer :images="[pageInfo.aheadWaistImageMark]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                        <img class="img" alt="" :src="pageInfo.aheadWaistImageMark">
                    </el-tooltip>
                </viewer>
                <div class="img-title">前面90°弯腰位</div>
              </div>

              <div class="imginner" v-if="(pageInfo.behindWaistImageMark !=null)">
                <viewer :images="[pageInfo.behindWaistImageMark]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                        <img class="img" alt="" :src="pageInfo.behindWaistImageMark" >
                    </el-tooltip>
                </viewer>
                <div class="img-title">背面90°弯腰位</div>
              </div>

              <div class="imginner" v-if="(pageInfo.xraysFileMarkZ !=null)">
                <viewer :images="[pageInfo.xraysFileMarkZ]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                        <img class="img" alt="" :src="pageInfo.xraysFileMarkZ" >
                    </el-tooltip>
                </viewer>
                <div class="img-title">X光片（站立位全脊柱正位）</div>
              </div>
          </div>

          <!-- 评估结果 -->
          <div class="eva-rsult">
              <span class="result-title">评估结果：</span>
              <div style="display:flex;" >
              <table border="1" style="width:362px;margin-top:14px;margin-bottom: 40px" cellspacing="0" cellpadding="8">
                        <tr style="text-align:center;color:#858585;font-size:14px;">
                            <td style="width:74px">项目</td>
                            <td style="width:110px">ATR</td>
                            <td style="width:180px">Cobb角</td> 
                        </tr>
                        <tr style="text-align:center;color:#858585;font-size:14px">
                            <td class="tdStyle">测量值</td>
                            <td class="tdStyle">{{atr}}</td>
                            <td class="tdStyle">{{cobb}}</td>
                        </tr>
                        <tr style="text-align:center;color:#858585;font-size:14px;">
                            <td class="tdStyle">标准范围</td>
                            <td class="tdStyle">0-5°</td>
                            <td class="tdStyle">0-10°</td>
                        </tr>
              </table>
              <!-- 评估结果 -->
              <div style=" margin-bottom: 40px">
                    <div class="eva-last">该受检者患有脊柱侧弯的概率：{{result}}</div>
                    <div class="eva-last" style="margin-top: 10px !important">该受检者专业脊柱评估结果：{{resultnew}}</div>
                    <div class="eva-last" style="margin-top: 10px !important">高低肩评估结果：{{shoulderResult}}</div>
                    <div class="eva-last" style="margin-top: 10px !important">高低肩值：{{shoulderValue}}</div>
                    <div class="eva-last" style="margin-top: 10px !important">脊柱偏移值：{{spinalOffset}}</div>
              </div>
            
               <!-- 发送报告 -->
              <div class="sendreport" @click="sendNotice">发送报告</div>
              <div class="analysisreport" @click="clickAnalysis" v-if="pageInfo.status =='已处理'">对该报告解析</div>
              </div>
          </div>
          <!-- 更新计算结果弹窗 -->
          <el-dialog
            title="更新计算结果"
            :showClose="false"
            :visible.sync="showUpdate"
            width="390px"
            center>
          <!-- 内容区 -->
          <el-form ref="appendForm" :model="upForm" label-width="100px">
              <el-form-item label="检测结果">
                <el-select v-model="upForm.checkResult"
                  placeholder="请选择检测结果" style="width:200px !important"
                  clearable filterable
                  size="medium">
                      <el-option
                        v-for="(item,index) in checkList"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="高低肩值">
                <el-input type="number" v-model="upForm.shoulder" style="width:200px !important"></el-input>
              </el-form-item>
            </el-form>
            <!-- footer -->
            <div style="width:440px;display:flex;justify-content:space-around;margin-top:40px;margin-left:-46px">
              <div style="width:80px;height:32px;font-size:16px;color:#fff;cursor:pointer;background-color: #00cccc;line-height: 32px; border-radius:4px;text-align: center" @click="cancelUP">取消</div>
              <div style="width:80px;height:32px;font-size:16px;color:#fff;cursor:pointer;background-color: #00cccc;line-height: 32px; border-radius:4px;text-align: center" @click="sureUP">确认</div>
            </div>
          </el-dialog>
          <!-- 不合格数据弹窗 -->
          <el-dialog
            title="不合格数据反馈"
            :showClose="false"
            :visible.sync="showErro"
            @close="closeErroPro"
            width="390px"
            center>
          <!-- 内容区 -->
          <el-form ref="appendForm" :model="erroForm" label-width="100px">
              <el-form-item label="错误体位1">
                <el-select v-model="erroForm.title1"
                  placeholder="请选择错误体位" style="width:200px !important"
                  clearable filterable
                  size="medium">
                      <el-option
                        v-for="(item,index) in erroList"
                        :key="index"
                        :label="item.label"
                        :disabled="item.disabled"
                        :value="item.value">
                      </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="错误详情">
                <el-input type="textarea" autosize v-model="erroForm.detail1" style="width:200px !important"></el-input>
              </el-form-item>
              <!-- ero2 -->
              <el-form-item v-if="erroIndex>=2" label="错误体位2">
                <el-select v-model="erroForm.title2"
                  placeholder="请选择错误体位" style="width:200px !important"
                  clearable filterable
                  size="medium">
                      <el-option
                        v-for="(item,index) in erroList"
                        :key="index"
                        :label="item.label"
                        :disabled="item.disabled"
                        :value="item.value">
                      </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="erroIndex>=2" label="错误详情">
                <el-input type="textarea" autosize v-model="erroForm.detail2" style="width:200px !important"></el-input>
              </el-form-item>
              <!-- erro3 -->
              <el-form-item v-if="erroIndex>=3" label="错误体位3">
                <el-select v-model="erroForm.title3"
                  placeholder="请选择错误体位" style="width:200px !important"
                  clearable filterable
                  size="medium">
                      <el-option
                        v-for="(item,index) in erroList"
                        :key="index"
                        :label="item.label"
                        :disabled="item.disabled"
                        :value="item.value">
                      </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="erroIndex>=3" label="错误详情">
                <el-input type="textarea" autosize v-model="erroForm.detail3" style="width:200px !important"></el-input>
              </el-form-item>
            </el-form>
            <!-- footer -->
              <div class="send-footer">
                <div style="font-size:30px;cursor: pointer;"><i class="el-icon-circle-plus" @click="addErro"></i></div>
                <div class="send" @click="sendFailReports">发送报告</div>
              </div>
          </el-dialog>


          <!-- 解析报告弹窗 -->
          <el-dialog
            title="报告解析"
            :showClose="false"
            :visible.sync="analysisDialog"
            width="390px"
            center>
          <!-- 内容区 -->
          <el-form ref="appendForm" :model="analysisForm" label-width="30px">
              <el-form-item>
                <el-input type="textarea" :autosize="{minRows: 3}" v-model="analysisForm.expertAnalysisResult" style="width:300px !important"></el-input>
              </el-form-item>
            </el-form>
            <!-- footer -->
              <div class="send-footer">
                <div class="send" @click="sendAnalysisReports">发送解析</div>
              </div>
          </el-dialog>
      </div>
  </div>
</template>

<script>
import {viewReportData,downloadOrgData,uploadReportImage,sendReport,sendFailReport,getBaseInfo,sendSMS,nosendSMS,getNewBackImg,updateResult,sendAnalysisReport} from '@/api/userManage.js'
export default {
  beforeRouteLeave(to,from,next){
    if((to.name === 'Major')||(to.name==='SpineEvaluation')){
      to.meta.keepAlive = true;
    } 
    next();
  },
      data(){
        
        return{
          newId1:null,
          newId2:null,
          topData:null,//表头数据
          id:'',
          pageId:'',
          pageInfo:'',
          fatherInfo:{
            name:null,
            gender:null,
            mobile:null,
            cardId:null,
          },
          atr:'-',
          cobb:'-',
          result:'-',
          resultnew:'-',
          upFile:new FormData(),
          newBackImg:null,//背部图像二次计算图
          showUpdate:false,//更新计算结果弹窗
          shoulderValue:'-',//高低肩值
          shoulderResult:'-',
          spinalOffset:'-',
          upForm:{
            checkResult:null,
            shoulder:null
          },
          checkList:[
          {label:'正常',value: '正常'},
          {label:'姿态异常',value: '姿态异常'},
          {label:'疑似脊柱侧弯',value: '疑似脊柱侧弯'},
          {label:'检测异常',value: '检测异常'},
          ],
          erroList:[ 
            {label:'背面站立位',value: '背面站立位', disabled: false},
            {label:'前面90°弯腰位',value: '前面90°弯腰位', disabled: false},
            {label:'背面90°弯腰位',value: '背面90°弯腰位',disabled: false},
          ],
          erroForm:{
            title1:null,
            detail1:null,
            title2:null,
            detail2:null,
            title3:null,
            detail3:null,
          },
          erroIndex:1,
          showErro:false,//不合格数据弹窗
          analysisDialog: false,//解析报告弹窗
          analysisForm: {
            expertAnalysisResult: null
          }
        }
      },
      created(){
        if(this.$route.query){
              this.newId1 = this.$route.query.rid;
              this.getTopData(this.newId1);
              //加载页面数据
              this.newId2 = this.$route.query.id;
              this.getInfoById(this.newId2);
        }
      },
     
      methods:{
        //解析报告
        clickAnalysis(){
          this.analysisDialog = true;
          this.analysisForm= {
            expertAnalysisResult: null
          }
        },
        sendAnalysisReports(){
          if(this.analysisForm.expertAnalysisResult!=null){
            sendAnalysisReport({
              id:this.pageId,
              ...this.analysisForm
            }).then(res=>{
              if(res.data.status===200){
                this.getInfoById(this.newId2);//更新页面数据 
                this.$message({
                  message: '发送成功！',
                  type: 'success'
                });
                this.analysisDialog = false;
              }else{
                this.$message.error(res.data.message);
              }
            })
            }else{
              this.$message.error("请先填写报告解析信息！");
            }
        },

        //面包屑返回
        goback1(){
               this.$router.go(-1);
              },
            //表头数据
            getTopData(id){
              getBaseInfo({
                    id:id
                }).then(res=>{
                  if(res.data.status == 200){
                      this.topData=res.data.data;
                  }else{
                      this.$message.error(res.data.message);
                  } 
                }).catch(res=>{
                    this.$message.error(res);
                })
            },
            //加载页面数据
            getInfoById(id){
              viewReportData({
                id:id
              }).then(res=>{
                if(res.data.status == 200){
                      //console.log("id测试",res.data)
                      this.pageInfo=res.data.data;
                      this.pageId = res.data.data.id;
                      this.atr = res.data.data.atrAngle;
                      this.cobb = res.data.data.cobbAngle;
                      this.result = res.data.data.riskResult; 
                      this.resultnew = res.data.data.examineResult;
                      this.shoulderValue = res.data.data.unevenShoulder;
                      this.shoulderResult = res.data.data.shoulderResult;
                      this.fatherInfo.status =  res.data.data.status;
                      this.spinalOffset = res.data.data.spineOffset;
                }else{
                    this.$message.error(res.data.message);
                }
              }).catch(res=>{
                 this.$message.error(res);
              })
            },
            //下载原始数据  64id 有数据 /二进制流下载文件方法
            getOrgData(){
              const loading = this.$loading({
                lock: true,
                text: '下载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });

                 downloadOrgData({
                id:this.pageId
                }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                      if (res.data.type === 'application/json') {
                              const reader = new FileReader()
                              reader.readAsText(res.data)
                              reader.onload = e => {
                              const resData = JSON.parse(reader.result)
                              if(resData.status!=200){
                                  loading.close();
                                  this.$message.error(resData.message)
                              }
                          }
                      }
                      else{
                          const { data, headers } = res
                          const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                          //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                          const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                          const blob = new Blob([data], { type:'application/zip'})
                          let dom = document.createElement('a')
                          let url = window.URL.createObjectURL(blob)
                          dom.href = url
                          dom.download = decodeURI(fileName)
                          dom.style.display = 'none'
                          document.body.appendChild(dom)
                          dom.click();
                          dom.parentNode.removeChild(dom)
                          window.URL.revokeObjectURL(url)
                          loading.close();
                      }
                        
            }).catch((res) => {
                    this.$message.error(res);
                  });          
            },
            //上传数据
            beforeUpload(file){
                      //console.log("上传",file) ;
                      var msg=file.name.substring(file.name.lastIndexOf('.')+1);
                      const fileType = msg==='zip';
                      if(!fileType){
                          this.$message({
                              type:'warning',
                              message:'上传类型只能是zip格式'
                          })
                      }            
                      return fileType ; 
            },
    
            httprequest(par){
                    // console.log("par",par)
                      var uploadFile = par.file;
                      var formData = new FormData();
                      formData.append('file',uploadFile);
                      uploadReportImage(
                        formData
                      ).then(res=>{
                          if(res.data.status == 200){
                                location.reload();//刷新
                                this.$message({
                                message: '上传成功！',
                                type: 'success'
                              });
                                }else{
                                      this.$message.error(res.data.message);
                                    }
                          }).catch(res=>{
                            this.$message.error(res);
                          })
              },
            remove(){},//移除以上传的--暂不需要

          //发送报告
          sendNotice(){
            sendReport({
              id:this.pageId
            }).then(res=>{
                if(res.data.status == 200){
                this.getInfoById(this.newId2);//更新页面数据 
                this.$message({
                message: '下发报告成功！',
                type: 'success'
                  });
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
              this.$message.error(res);
            })
          },
          //发送短信--不合格报告
          startFailProp(){
            this.showErro = true;
          },
          //新增错误体位
          addErro(){
            if(this.erroIndex>2){
              this.$message.error("不能再添加了");
            }else{
              this.erroIndex++;
              //判断哪些选项被选过后不能再选了
             if(this.erroIndex===2){
                var status2 = this.erroForm.title1;
                switch (status2) {
                  case '背面站立位':
                    this.erroList[0].disabled = true;
                    break;
                  case '前面90°弯腰位':
                    this.erroList[1].disabled = true;
                    break;
                  case '背面90°弯腰位':
                    this.erroList[2].disabled = true;
                    break;
                
                  default:
                    console.log('other')
                    break;
                }
             }
             if(this.erroIndex===3){
                var status31 = this.erroForm.title1;
                var status32 = this.erroForm.title2;
                //ab
                if((status31==='背面站立位')&&(status32==='前面90°弯腰位')){
                  this.erroList[0].disabled = true;
                  this.erroList[1].disabled = true;
                }
                //ac
                if((status31==='背面站立位')&&(status32==='背面90°弯腰位')){
                  this.erroList[0].disabled = true;
                  this.erroList[2].disabled = true;
                }
                //bc
                if((status31==='前面90°弯腰位')&&(status32==='背面90°弯腰位')){
                  this.erroList[1].disabled = true;
                  this.erroList[2].disabled = true;
                }
             }
            }
          },
          //关闭弹窗 清空
          closeErroPro(){
            this.erroList[0].disabled = false;
            this.erroList[1].disabled = false;
            this.erroList[2].disabled = false;
            this.erroForm.title1 = null;
            this.erroForm.detail1 = null;
            this.erroForm.title2 = null;
            this.erroForm.detail2 = null;
            this.erroForm.title3 = null;
            this.erroForm.detail3 = null;
            this.erroIndex = 1;
          
          },
          //发送错误信息订阅
          sendFailReports(){
            if((this.erroForm.title1!=null)&&(this.erroForm.detail1!=null)){
              //体位1的情况
              var last11=null;
              var last12=null;
              var last13=null;
              if(this.erroForm.title1==='背面站立位'){
                last11 = this.erroForm.detail1;
              }
              if(this.erroForm.title1==='前面90°弯腰位'){
                last12 = this.erroForm.detail1;
              }
              if(this.erroForm.title1==='背面90°弯腰位'){
                last13 = this.erroForm.detail1;
              }
              //体位2的情况
              var last21=null;
              var last22=null;
              var last23=null;
              if(this.erroForm.title2==='背面站立位'){
                last21 = this.erroForm.detail2;
              }
              if(this.erroForm.title2==='前面90°弯腰位'){
                last22 = this.erroForm.detail2;
              }
              if(this.erroForm.title2==='背面90°弯腰位'){
                last23 = this.erroForm.detail2;
              }
              //体位3的情况
              var last31=null;
              var last32=null;
              var last33=null;
              if(this.erroForm.title3==='背面站立位'){
                last31 = this.erroForm.detail3;
              }
              if(this.erroForm.title3==='前面90°弯腰位'){
                last32 = this.erroForm.detail3;
              }
              if(this.erroForm.title3==='背面90°弯腰位'){
                last33 = this.erroForm.detail3;
              }
              sendFailReport({
              reportDataDetailId:this.pageId,
              backHalfErrorFeedback:last11 || last21 || last31,
              aheadWaistErrorFeedback:last12 || last22 || last32,
              behindWaistErrorFeedback:last13 || last23 || last33,
            }).then(res=>{
              if(res.data.status===200){
                this.getInfoById(this.newId2);//更新页面数据 
                this.$message({
                message: '发送成功！',
                type: 'success'
                  });
                  this.showErro = false;
                  this.closeErroPro();//clear
              }else{
                this.$message.error(res.data.message);
              }
            })
            }else{
              this.$message.error("请先填写错误信息！");
            }
          },
          //发送短信通信通知
          sendSMS(){
            sendSMS({
              id:this.pageId
            }).then(res=>{
                if(res.data.status == 200){
                  this.getInfoById(this.newId2);//更新页面数据 
                  this.$message({
                  message: '发送成功！',
                  type: 'success'
                  });
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
              this.$message.error(res);
            })
          },
          nosendSMS(){
            //console.log("不发送");//nosendSMS
            nosendSMS({
              id:this.pageId
            }).then(res=>{
                if(res.data.status == 200){
                  this.getInfoById(this.newId2);//更新页面数据 
                  this.$message({
                  message: '发送成功！',
                  type: 'success'
                  });
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
              this.$message.error(res);
            })
          },
          //获取背部图二次计算
          getBackImg(){
            getNewBackImg({id:this.newId2}).then(res=>{
              if(res.data.status==200){
                this.$message({
                  message: '操作成功！',
                  type: 'success'
                  });
              }else{
                this.$message.error(res.data.message);
              }
            })
          },
          //更新算法计算结果
          updateMark(){
            this.showUpdate = true;
            //回显
            this.upForm.checkResult = this.resultnew;
            this.upForm.shoulder = this.shoulderValue;
          },
          //取消更新
          cancelUP(){
            this.showUpdate = false;
            this.upForm.checkResult = null;
            this.upForm.shoulder = null;
          },
          //确认
          sureUP(){
            updateResult({
                id:this.newId2,
                examineResult:this.upForm.checkResult,
                unevenShoulder:this.upForm.shoulder
            }).then(res=>{
              if(res.data.status==200){
                this.$message({
                  message: '更新成功！',
                  type: 'success'
                  });
                  this.getTopData(this.newId1);
                  this.getInfoById(this.newId2);
                  this.showUpdate = false;
              }else{
                this.$message.error(res.data.message);
              }
            })
          }
   }
}
</script>

<style lang="stylus" scoped>
//deep
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
/deep/.send-footer{
  width:100%;
  height: 32px;
  display:flex;
  align-items: center;
  justify-content: center
}
/deep/.send{
  width: 160px ;
  cursor pointer;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: #00CCCC;
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
  border-radius: 24px;
}
//component style 
.blueTitle
  font-size: 16px 
  color: #353535
  line-height: 28px 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:4px
    background-color: #00CCCC

.eva-last{
    margin-left: 38px;
    font-size: 16px;
    margin-top: 110px;
    color: #353535;
    opacity .8
}
.sendreport
  width: 116px 
  height: 32px 
  position: absolute
  right: 60px
  background-color: #00cccc
  color: #fff
  text-align: center
  line-height: 32px  
  font-size:14px
  margin-top: 110px;
  cursor pointer

.analysisreport
  width: 116px 
  height: 32px 
  position: absolute
  right: 60px
  background-color: #009dd9
  color: #fff
  text-align: center
  line-height: 32px  
  font-size:14px
  margin-top: 150px;
  cursor pointer

.sendsms
  width: 116px 
  height: 32px 
  background-color: #00cccc
  color: #fff
  text-align: center
  line-height: 32px  
  font-size:14px
  margin-top: 110px;
  margin-left: 0.2rem
  cursor pointer


.org-img
  margin-top: 34px 
  font-size: 16px  
  color: #353535
.data-img
  margin-top: 54px 
  font-size: 16px  
  color: #353535


//normal 
.evadetail
  overflow hidden
  .changeBox
    margin-top: 18px
    display: flex
    .orgdata
        width: 116px 
        cursor pointer
        height: 32px
        line-height: 32px
        text-align: center
        background-color: #00CCCC
        color: #fff
        margin-right: 12px
        font-size: 14px
  .baseInfo
    width: 100%
    height: 120px
    background-color: #FFFFFF
    padding: 24px 28px 0 25px
    box-sizing: border-box
    margin-top: 14px
    .title
      display: flex
      justify-content: space-between
      align-items: center
      .reportStatus
        color: #FC5555
        font-size: 22px
        opacity .8
    .infomation
      margin-left: 0px
      margin-top: 22px 
      display: flex
      justify-content: space-around
      flex-wrap: wrap
      .info-text
        font-size: 14px
        color: #858585
  .baseBottom
    width: 100%
    box-sizing: border-box
    padding: 24px 28px 0 25px 
    background-color: #fff
    margin-top: 14px
    .imgBox
      margin-left: 8px 
      margin-top: 26px 
      display: flex
      flex-wrap: wrap
      .imginner
        width: 252px 
        margin-right:50px
        margin-bottom: 26px
        text-align: center
        .img
          width: 252px
          height: 354px 
          object-fit: cover
        .img-title
          font-size: 16px 
          color: #353535
          opacity .8
          margin-top: 26px
    .eva-rsult
      margin-top: 38px 
     
      .result-title
        font-size: 16px 
        color: #353535
        opacity .8
      
</style>